//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SliderItem',
  data() {
    return {
      display: false,
      isInit: false,
      initAnimation: false,
      direction: false,
      animation: 'normal',
      speed: 500,
      zIndex: 99,
      width: '100%',
    }
  },
  created() {
    this.$parent.$emit('slider:init')
    this.speed = this.$parent.speed || 500
    this.animation = this.$parent.animation || 'normal'
    this.width = this.$parent.itemWidth || '100%'
  },
  destroyed() {
    this.$parent.$emit('slider:init')
  },
  methods: {
    init() {
      if (this.isInit) {
        return
      }
      this.isInit = true
      this.display = true
      this.initAnimation = true
      this.$nextTick(() => (this.initAnimation = false))
    },
    // direction: left: true, right: false
    show(direction) {
      this.zIndex = 99
      this.direction = direction
      this.$nextTick(() => (this.display = true))
    },
    hide(direction) {
      this.zIndex = 98
      this.direction = direction
      this.$nextTick(() => (this.display = false))
    },
    animationAfterLeave(){
      this.$parent.animationAfterLeave()
    }
  },
}
