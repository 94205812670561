class Dot {
  constructor(_x, _y, _color, ctx){
    this.x = Math.random() * ctx.canvas.width
    this.y = Math.random() * ctx.canvas.height
    this.dest = {
      x: _x,
      y: _y
    }

    this.vx = (Math.random() -0.5) * 20
    this.vy = (Math.random() -0.5) * 20
    this.accX = 0
    this.accY = 0
    this.friction = Math.random()*0.05 + 0.93


    this.speed = Math.random() * 2 + 0.3
    this.angle = Math.random() * 360
    this.color = _color
    this.radius = Math.random() * 10 + 3
    this.flag = Math.floor(Math.random() * 2)

  }
  render(ctx, x, y){
    ctx.save()
    ctx.fillStyle = this.color
    ctx.strokeStyle = this.color
    // TODO 距离逻辑
    this.accX = (this.dest.x - this.x)/1000;
    this.accY = (this.dest.y - this.y)/1000;
    this.vx += this.accX;
    this.vy += this.accY;
    this.vx *= this.friction;
    this.vy *= this.friction;

    
    this.x += this.vx;
    this.y +=  this.vy;


    ctx.beginPath()
    ctx.arc(this.x, this.y,this.radius, 0, 2 * Math.PI)
    ctx.closePath()
    ctx.fill()
    ctx.restore()

    
    if(x && y) {
      var a = this.x - x;
      var b = this.y - y;
      var distance = Math.sqrt( a*a + b*b );
      if(distance<(this.radius*40)){
        this.accX = (this.x - x)/100;
        this.accY = (this.y - y)/100;
        this.vx += this.accX;
        this.vy += this.accY;
      }
    }
  }

  update(){
    if(this.flag === 1) {
      this.radius += 0.05
      if (this.radius > 5) {
        this.flag = 0
      }
    }
    if(this.flag === 0) {
      this.radius -= 0.05
      if(this.radius < 2) {
        this.flag = 1
      }
    }
  }
}

export default class LogoManager{
  constructor(parentEle){
    if (!parentEle){
      throw new Error('父容器未传递')
    }
    this.parentEle = parentEle
    this.dotList = []
    this.canvas = document.createElement('canvas')
    this.parentEle.appendChild(this.canvas)
    this.canvas.width = parentEle.offsetWidth
    this.canvas.height = parentEle.offsetHeight
    this.canvas.style.display = 'block'
    this.canvas.style.position = 'absolute'
    this.canvas.style.top = '0'
    this.canvas.style.left = '0'
    this.ctx = this.canvas.getContext('2d')
    this.img = new Image()
    this.img.src = require('../../assets/img/canvaslogo.svg')
    this.img.addEventListener('load', this.imgloadEvent.bind(this))
    this.colors = ['#B52630','#B52630','#B52630', '#F47C39']
    this.evenTime = 0
    this.timer = null
  }
  init(){
    this.ctx.drawImage(this.img, 0,0)
    const imgData = this.ctx.getImageData(0, 0, this.img.width, this.img.height)
    for (let x=0; x<imgData.width;x+=10){
      for(let y=0;y<imgData.height;y+=10){
        var i = (y*imgData.width + x) * 4
        if(imgData.data[i+3] > 128 && imgData.data[i] > 100){
          this.dotList.push(new Dot(x + 700, y + this.canvas.height / 2 - this.img.height/2, this.colors[Math.floor(Math.random() * this.colors.length)], this.ctx))
        }
      }
    }
    this.canvas.addEventListener('mousemove', this.canvasMouseEvent.bind(this))

    this.loop()
  }

  render(){
    this.ctx.clearRect(0,0,this.ctx.canvas.width,this.ctx.canvas.height)
    this.dotList.forEach(item => {
      item.update()
      item.render(this.ctx)
    })
  }
  loop(){
    this.timer = requestAnimationFrame(this.requestEvent.bind(this))
  }
  requestEvent(){
    this.render()
    cancelAnimationFrame(this.requestEvent)
    this.timer = requestAnimationFrame(this.requestEvent.bind(this))
  }
  destroyed(){
    this.img.removeEventListener('load', this.imgloadEvent)
    this.canvas.removeEventListener('mousemove', this.canvasMouseEvent)
    cancelAnimationFrame(this.timer)
  }
  imgloadEvent(){
    this.init()
  }
  canvasMouseEvent(e){
    // const current = 
    // console.log('e', e)
    this.ctx.clearRect(0,0,this.ctx.canvas.width,this.ctx.canvas.height)
    this.dotList.forEach(item => {
      item.update()
      item.render(this.ctx, e.offsetX,e.offsetY)
    })
  }
}













































// export default class Logo {
//   constructor(parentEle){
//     if(!(parentEle instanceof HTMLElement)) {
//       throw new Error('容器组件未传递')
//     }
//     this.dotList = []
//     this.colors = ['#B52630','#B52630','#B52630', '#F47C39']
//     // this.r = 3
//     this.parentEle = parentEle
//     this.canvas = document.createElement('canvas')
//     this.canvas.height = parentEle.offsetHeight
//     this.canvas.width = parentEle.offsetWidth
//     this.ctx = this.canvas.getContext('2d')
//     parentEle.appendChild(this.canvas)
//     this.img = new Image()
//     this.img.src = require('../../assets/img/canvaslogo.svg')
//     this.img.onload = () =>{
//       this.init()
//     }
//     this.prevTime = 0
//     const self = this
//     this.canvas.addEventListener('mousemove', this.mousemoveEvent.bind(this))
//   }
//   init(){
//     this.ctx.drawImage(this.img, 0, 0)
//     const imgData = this.ctx.getImageData(0, 0, this.img.width, this.img.height)
//     for(let x = 0; x < this.img.width; x+=10){
//       for(let y = 0; y < this.img.height; y+=10){
//         const i = (y * imgData.width + x) * 4;
//         if(imgData.data[i+3] > 128 && imgData.data[i] > 100){
//           // console.log(imgData.data[i+3])
//           // var dot = new Particle(x + 600, y + (winHeight/2 - imgH/2));
//           // dotList.push(dot);
//           this.add(x, y)
//         }
//       }
//     }
//     this.render()
//     // consol e.log(this.dotList)
    
//   }
//   add(x, y){
//     this.dotList.push(new Dot({
//       x,
//       y,
//       r: Math.random() * 5,
//       color: this.colors[Math.floor(Math.random() * this.colors.length)]
//     }
//     ))
//   }
//   render(){
//     this.ctx.clearRect(0,0,this.canvas.width,this.canvas.height)
//     this.dotList.forEach(item => {
//       item.render(this.ctx)
//     })
//   }
//   update(x, y){
//     this.dotList.forEach(item => {
//       // if(Math.abs(item.x - x) < 50 && Math.abs(item.y - y) < 50) {
//         // console.log('接近中，需要更新', 'item.x',item.x,'x', x)
//         item.update(x, y)
//       // }
//     })
//   }
//   loop(){
//     setInterval(() => {
//       this.update()
//       this.render()
//     }, 30);
//   }
//   mousemoveEvent(e){
//     const current = new Date().getTime()
//     if(current - this.prevTime < 100) {
//       return
//     }
//     this.prevTime = current
//     // this.update(e.offsetX,e.offsetY)
//     this.render()
//     // console.log("e",e)
//   }
// }

// class Dot {
//   constructor(props){
//     this.dest = {
//       x: props.x,
//       y: props.y,
//     }
//     this.x = 0
//     this.y = 0
//     this.r = 5
//     this.scaleX = 1
//     this.scaleY = 1
//     this.color = '#000'
//     this.vx = 0
//     this.vy = 0
//     this.dx = parseInt(Math.random() * 10)
//     this.dy = parseInt(Math.random() * 10)
//     this.easing = 0.05
//     this.angle = 0
//     this.swing = 0.5
//     Object.assign(this, props)
//     console.log(this)
//   }
//   render(ctx){
//     ctx.save()
//     ctx.beginPath()
//     ctx.fillStyle = this.color
//     // ctx.scale(this.scaleX, this.scaleY);
//     ctx.arc(this.x, this.y, this.r, 0, 2 * Math.PI)
//     ctx.closePath()
//     ctx.fill()
//     ctx.restore()
//   }
//   update(x, y){
//     this.x = x ? x : this.x
//     this.y = y ? y : this.y
//     this.scaleX = this.scaleY = 1 + Math.sin(this.angle) *this.swing
//     this.angle += 0.05
//     this.angle %= Math.PI * 2
//     // this.r = Math.random() * 5
//     // console.log(this.x,this.y)
//     // if(Math.abs(this.x - x) < 50 && Math.abs(this.y - y) < 50) {
      
//     //   if (this.x < x && this.y > y){
//     //     console.log('第一象限')
//     //     this.x -= this.dx
//     //     this.y += this.dy
//     //   } else if (this.x < x && this.y < y) {
//     //     console.log('第二象限')
//     //     this.x -= this.dx
//     //     this.y -= this.dy
//     //   } else if (this.x > x && this.y < y) {
//     //     console.log('第三象限')
//     //     this.x += this.dx
//     //     this.y += this.dy
//     //   } else if (this.x > x && this.y > y) {
//     //     console.log('第四象限')
//     //     this.x += this.dx
//     //     this.y += this.dy
//     //   }

//     // } else {
//     //   if (this.x !== this.dest.x && this.y !== this.dest.y) {
//     //     console.log(this.x - this.dest.x)
//     //     console.log(this.y - this.dest.y)
//     //     this.x = this.x - x > 0 ? this.x - x > 0 : -Math.abs(this.x - x) * this.easing
//     //     console.log(this.x)
//     //     // console.log(this.x)
//     //     // this.y = this.y -y * this.easing
        
//     //   }
//     // }


//     // this.x = x
//     // console.log('x',x,'y',y)
//   }
// }
