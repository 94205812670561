let dots = []
const colors = ['#B52630','#B52630','#B52630', '#F47C39'];
let timer =null
class Dot{
  constructor(canvas,color){
    if (!canvas){
      throw new Error('未传递容器元素')
    }
    this.canvas = canvas
    // this.canvas = document.createElement('canvas')
    // this.canvas.width = parentEle.offsetWidth
    // this.canvas.height = parentEle.offsetHeight
    // this.parentEle.appendChild(this.canvas)
    this.x = Math.floor(Math.random() * this.canvas.width)
    this.y = Math.floor(Math.random() * this.canvas.height)
    this.r= Math.random() * 4
    this.color = color
  }
  render(ctx){
    ctx.save()
    ctx.beginPath()
    ctx.fillStyle= this.color
    ctx.arc(this.x,this.y,this.r, 0, 2*Math.PI)
    ctx.fill()
    ctx.closePath()
    ctx.restore()
  }
  update(x, y){
    this.x = x
    this.y = y
  }
}

function render(ctx){
  
  ctx.clearRect(0,0, ctx.canvas.width, ctx.canvas.height)
  dots.forEach(item=>{
    item.render(ctx)
  })
}

const direction =  360 * Math.random()
const step = 1 + Math.random()
function update(canvas){
  dots.forEach((item,i) => {
    // 根据rateX和rateY移动点的坐标
    if(item.x > 0 && item.x < canvas.width && item.y > 0 && item.y < canvas.height) {
      const angleRad = direction * Math.PI / 180
      item.update(item.x + step * Math.cos(angleRad), item.y + step * Math.sin(angleRad))
    } else {
      // 如果粒子运动超出了边界，将这个粒子去除，同时重新生成一个新点。
      dots.splice(i, 1);
      dots.push(new Dot(canvas, colors[Math.floor(Math.random() * colors.length)]))
    }
    // item.update()
  })
}

function init(parentEle){
  const canvas = document.createElement('canvas')
  canvas.width = parentEle.offsetWidth
  canvas.height = parentEle.offsetHeight
  canvas.height = parentEle.offsetHeight
  const ctx = canvas.getContext('2d')
  parentEle.appendChild(canvas)
  for (let i = 0; i< 50;i++) {
    dots.push(new Dot(canvas, colors[Math.floor(Math.random() * colors.length)]))
  }

  timer = setInterval(()=> {
    update(canvas)
    render(ctx)
  }, 30)
  // render(ctx)
  // console.log(dots)
}

function destroyed(){
  clearInterval(timer)
  dots = []
}

export default {
  init,
  destroyed
}